/**
 * Created by davidcaddy on 10/01/2017.
 */
import React from 'react';
import { Route, IndexRoute } from 'react-router'
import App from './App';
import Header from './appHeader';
import DevPortal from './devPortal/DevPortal.js'
import GameTester from './components/gameTester';
import Home from './components/home';
import Team from './components/team';
import About from './components/about';
import DevPortalLandingPage from './devPortal/DevPortalLandingPage';
import Login from './components/login';
import BranchSelectionView from './devPortal/storyboard/branchSelectionView';
import StoryboardTwo from './devPortal/storyboardTwo/storyboardTwo';
import Storyboard from './devPortal/storyboard/storyboard';

export default(
    <Route path="/" component={App}>
        <Route component={Header}>
            <IndexRoute component={Home} handler={'./components/home'} />
            <Route path="team" component={Team}/>
            <Route path="about" component={About}/>
        </Route>
        <Route component={DevPortal}>
            <Route path="devportal" component={DevPortalLandingPage}/>
            <Route path="projectaxolotl" component={GameTester}/>
            <Route path="storyboardbranches" component={BranchSelectionView}/>
            <Route path="storyboard(/:id)" component={StoryboardTwo}/>
            <Route path="storyboardhorizontal(/:id)" component={Storyboard}/>
            <Route path="login(/:redirectPath)(/:redirectPathTwo)" component={Login}/>
        </Route>
    </Route>
);
