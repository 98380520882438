import React, { Component } from 'react';
import './branchSelectionView.css';
import MenuBar from "../../components/menuBar";
import {browserHistory} from "react-router";
import firebase from 'firebase/app';
import 'firebase/database';
import DevPortalContext from "../Context/DevPortalContext";

const rootRef = firebase.database().ref();
const branchInfoRef = rootRef.child("storyboardData").child("branchInfo");
// const branchesRef = rootRef.child("storyboardData").child("branches");

class BranchSelectionView extends Component {

    static contextType = DevPortalContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            branchInfoData: {},
            context: {}
        };

        this.refreshData = this.refreshData.bind(this);
        this.generateBranchLinks = this.generateBranchLinks.bind(this);
        this.navigateToStoryboardBranch = this.navigateToStoryboardBranch.bind(this);
    }

    componentDidMount() {
        if (this.context.user != null) {
            this.refreshData();
        }

        if (!this.context.isFetchingUser && this.context.user == null) {
            browserHistory.push('/login/storyboardbranches');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context !== this.state.context) {
            if (this.context.user != null) {
                this.refreshData();
            }
            else {
                if (!this.context.isFetchingUser) {
                    browserHistory.push('/login/storyboardbranches');
                }
            }

            this.setState({
                context: this.context
            });
        }
    }

    refreshData()  {
        branchInfoRef.on("value", snapshot => {
            if (this.refs.branchSelectionView) {
                this.setState({
                    branchInfoData: snapshot.val(),
                    isLoading: false
                });
            }
        });
    }

    navigateToStoryboardBranch(branchId) {
        browserHistory.push(`/storyboard/${branchId}`);
    }

    generateBranchLinks() {
        let branchButtons = [];

        for (let key in this.state.branchInfoData) {
            if (this.state.branchInfoData.hasOwnProperty(key)) {
                let branchInfo = this.state.branchInfoData[key];

                let info = {
                    title: branchInfo.title,
                    description: branchInfo.description,
                    key: key
                };

                branchButtons.push(
                    <button
                    className="BranchSelectionViewButton"
                    key={key}
                    onClick={() => {this.navigateToStoryboardBranch(info.key)}}>
                        {branchInfo.title}
                    </button>);
            }
        }

        return branchButtons;
    }

    render() {
        let tabData = [
            {title: "Home", action: ()=>{browserHistory.push('/')}},
            {title: "Dev Portal", action: ()=>{browserHistory.push('/devportal')}}
        ];

        return (
            <div  ref="branchSelectionView" className="BranchSelectionView">
                <MenuBar tabData={tabData}/>
                <div className="BranchSelectionViewBody">
                    <div className={this.state.isLoading ? "activityIndicator" : "activityIndicatorHidden"}/>
                    <div className="BranchSelectionViewLinkContainer">
                        {this.generateBranchLinks()}
                    </div>
                </div>
            </div>
        );
    }
}

export default BranchSelectionView;