/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './chapterTwo.css';
import NodeTwo from './nodeTwo.js'
import deleteIcon from '../../resources/deleteIcon.svg';

class ChapterTwo extends Component {

    constructor() {
        super();

        this.generateNodes = this.generateNodes.bind(this);
    }

    generateNodes() {
        let nodeData = [];
        for (let nodeKey in this.props.nodeData) {
            if (this.props.nodeData.hasOwnProperty(nodeKey)) {
                let node = this.props.nodeData[nodeKey];
                node.isFirst = false;
                node.isLast = false;
                node.id = nodeKey;
                nodeData.push(this.props.nodeData[nodeKey]);
            }
        }

        let sortedNodeData = nodeData.sort((a, b) => {return (b.index < a.index) ? 1 : (b.index > a.index) ? -1 : 0;});
        if (sortedNodeData.length > 0) {
            sortedNodeData[0].isFirst = true;
            sortedNodeData[sortedNodeData.length - 1].isLast = true;
        }

        let nodes = [];
        for (let node of sortedNodeData) {
            let connectionColors = {};
            if (this.props.connectionColors != null) {
                if (this.props.connectionColors.hasOwnProperty(node.id)) {
                    connectionColors = this.props.connectionColors[node.id];
                }
            }

            let nodeInfo = {
                title: node.title,
                description: node.description,
                chapterIndex: this.props.index,
                // just "chapter" for Legacy reasons
                chapter: this.props.index,
                chapterData: this.props.chapterData,
                id: node.id,
                index: node.index
            };

            nodes.push(<NodeTwo key={node.title}
                             title={node.title}
                             isFirst={node.isFirst}
                             isLast={node.isLast}
                             description={node.description}
                             connectionColors={connectionColors}
                             editAction={() => {
                                 this.props.nodeSelectAction(nodeInfo)
                             }}
                             index={node.index}
                             moveLeftAction={() => {
                                 this.props.nodeMoveAction({type: "left", target: nodeInfo})
                             }}
                             moveRightAction={() => {
                                 this.props.nodeMoveAction({type: "right", target: nodeInfo})
                             }}
                             deleteAction={() => {
                                 this.props.nodeDeleteAction({type: "nodeDelete", target: nodeInfo})
                             }}
            />);
        }

        return nodes;
    }

    render() {

        let deleteButton = <div />;
        if ((this.props.nodeData == null) || (this.props.nodeData.length === 0)) {
            deleteButton = <img src={deleteIcon} className="chapterTwoDeleteIcon" alt="delete" onClick={this.props.onDelete}/>;
        }

        return (
            <div className="chapterTwoBlock">
                <div className="chapterTwoHeader">
                    {deleteButton}
                    <div className="chapterTwoTitleLabel" onClick={this.props.onChapterSelect}>{this.props.title}</div>
                </div>
                <div className="chapterTwoNodeContainer">
                    {this.generateNodes()}
                </div>
            </div>
        );
    }
}

export default ChapterTwo;