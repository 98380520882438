/**
 * Created by david on 24/01/2017.
 */

import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div>
                <p>AXADAL is a small interative content studio based in Sydney and Melbourne Australia.</p>
                <p>Follow us on <a href="https://twitter.com/axadalstudios">Twitter</a> for updates!</p>
            </div>
        );
    }
}

export default About;
