/**
 * Created by davidcaddy on 29/01/2017.
 */

import React, { Component } from 'react';
import './connections.css';


class Connections extends Component {

    makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    drawArrow(context, tailPoint, headPoint, color) {

        //// Resize to Target Frame
        context.save();

        //// Variable Declarations
        let tailYAdjusted = tailPoint.y - 2;
        let headXAdjusted = headPoint.x - 8;
        let headYAdjusted = headPoint.y - 5;

        //// Frames
        let tailFrame = this.makeRect(tailPoint.x, (tailYAdjusted + 1), 1, 4);
        let headFrame = this.makeRect(headXAdjusted, (headYAdjusted + 1), 8, 10);

        //// Bezier Drawing
        context.beginPath();
        context.moveTo(tailFrame.x + 0.00000 * tailFrame.w, tailFrame.y + 0.50000 * tailFrame.h);
        context.bezierCurveTo(tailFrame.x + 17.00000 * tailFrame.w, tailFrame.y + 0.50000 * tailFrame.h, headFrame.x + -2.12500 * headFrame.w, headFrame.y + 0.50000 * headFrame.h, headFrame.x + 0.12500 * headFrame.w, headFrame.y + 0.50000 * headFrame.h);
        context.strokeStyle = color;
        context.lineWidth = 4;
        context.stroke();

        //// Polygon Drawing
        context.save();
        context.translate(headFrame.x + headFrame.w - 5, headFrame.y + 5);
        context.rotate(90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -5);
        context.lineTo(5.2, 2.5);
        context.lineTo(-5.2, 2.5);
        context.closePath();
        context.fillStyle = color;
        context.fill();

        context.restore();

        context.restore();
    }

    initializeContext(width, height) {
        // if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        let canvas = this.refs.canvas;
        let context = canvas.getContext('2d');

        let devicePixelRatio = window.devicePixelRatio || 1;
        let backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        let pixelRatio = devicePixelRatio / backingStorePixelRatio;

        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
        canvas.width = width * pixelRatio;
        canvas.height = height * pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return context;
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateCanvas();
    }

    updateCanvas() {
        const ctx = this.initializeContext(this.props.width, this.props.height);

        for (let connection of this.props.connections) {
            //Ensure to only render connections going from left to right
            if (connection.tail.x < connection.head.x) {
                this.drawArrow(ctx, connection.tail, connection.head, connection.color);
            }
        }
    }

    render() {
        return (
            <div className="connectionCanvasContainer" style={{"width": this.props.width, "height": this.props.height}}>
                <canvas className="connectionCanvas" ref="canvas" width={this.props.width} height={this.props.height}/>
            </div>
        );
    }
}

export default Connections;
