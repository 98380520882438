import React, { Component } from 'react';
import './DevPortal.css';
import firebase from 'firebase/app';
import DevPortalContext from './Context/DevPortalContext.js';
import deleteIcon from "../resources/deleteIcon.svg";

// Initialize Firebase
let config = {
    apiKey: "AIzaSyANHZu0buZ2VhmO4EIhdJZNSsWEYiPidhg",
    authDomain: "axadal-624dd.firebaseapp.com",
    databaseURL: "https://axadal-624dd.firebaseio.com",
    storageBucket: "axadal-624dd.appspot.com",
    messagingSenderId: "763672656949"
};
firebase.initializeApp(config);

class DevPortal extends Component {

    constructor() {
        super();

        this.state = {
            user: null,
            isFetchingUser: true
        };

        this.onLogOut = this.onLogOut.bind(this);
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    isFetchingUser: false,
                    user: user
                });
            }
            else {
                this.setState({
                    isFetchingUser: false
                });
            }
        });
    }

    onLogOut(event) {
        firebase.auth().signOut().then(() => {
            this.setState({
                isFetchingUser: false,
                user: null
            });
        }, (error) => {
            // An error happened.
        });
    }

    render() {
        return (
            <DevPortalContext.Provider value={{isFetchingUser: this.state.isFetchingUser, user: this.state.user}}>
                <div className="DevPortal">
                    <div className={this.state.user != null ? "DevPortalUserView" : "DevPortalHidden"}>
                        <img title="Log Out" src={deleteIcon} className="LogOutDeleteIcon" alt="Log Out" onClick={this.onLogOut}/>
                        <div className="DevPortalUserViewLabel">{this.state.user != null ? this.state.user.displayName : ""}</div>
                    </div>
                    {this.props.children}
                </div>
            </DevPortalContext.Provider>
        );
    }
}

export default DevPortal;