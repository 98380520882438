/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './storyboardTwo.css';
import ChapterTwo from './chapterTwo.js';
import MenuBar from '../../components/menuBar.js';
import ChapterEditView from '../storyboard/chapterEditView.js'
import NodeEditView from '../storyboard/nodeEditView.js'
import DeleteDialog from '../storyboard/deleteDialog.js'
import {browserHistory} from "react-router";
import DevPortalContext from '../Context/DevPortalContext.js';
import firebase from 'firebase/app';
import 'firebase/database';

// Initialize Firebase
// let config = {
//     apiKey: "AIzaSyANHZu0buZ2VhmO4EIhdJZNSsWEYiPidhg",
//     authDomain: "axadal-624dd.firebaseapp.com",
//     databaseURL: "https://axadal-624dd.firebaseio.com",
//     storageBucket: "axadal-624dd.appspot.com",
//     messagingSenderId: "763672656949"
// };
// firebase.initializeApp(config);

const rootRef = firebase.database().ref();
const rootStoryboardRef = rootRef.child("storyboardData").child("branches");
let storyboardRef = rootStoryboardRef.child("branch1");

class StoryboardTwo extends Component {

    static contextType = DevPortalContext;

    constructor(props) {
        super(props);

        this.state = {
            showChapterEditView: false,
            showNodeEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: null,
            selectedNode: null,
            storyboardData: {content: {}, connections: {}},
            isLoading: true,
            context: {}
        };

        if (this.props.params.id != null) {
            storyboardRef = rootStoryboardRef.child(this.props.params.id);
        }

        this.currentNodes = [];

        this.generateStoryboard = this.generateStoryboard.bind(this);
        this.generateConnectionData = this.generateConnectionData.bind(this);
        this.showChapterEdit = this.showChapterEdit.bind(this);
        this.onHideChapterEdit = this.onHideChapterEdit.bind(this);
        this.showNodeEdit = this.showNodeEdit.bind(this);
        this.onHideNodeEdit = this.onHideNodeEdit.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.getChapterIdForNode = this.getChapterIdForNode.bind(this);
        this.onMoveNode = this.onMoveNode.bind(this);
        this.refreshData = this.refreshData.bind(this);

        this.testGraphDraw = this.testGraphDraw.bind(this);
    }

    componentDidMount() {
        if (this.context.user != null) {
            this.refreshData();
        }

        if (this.props.params.id == null) {
            browserHistory.push('/login/storyboardbranches');
        }

        if (!this.context.isFetchingUser && this.context.user == null) {
            if (this.props.params.id != null) {
                browserHistory.push(`/login/storyboard/${this.props.params.id}`);
            }
            else {
                browserHistory.push('/login/storyboard');
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context !== this.state.context) {
            if (this.context.user != null) {
                this.refreshData();
            }
            else {
                if (!this.context.isFetchingUser) {
                    if (this.props.params.id != null) {
                        browserHistory.push(`/login/storyboard/${this.props.params.id}`);
                    }
                    else {
                        browserHistory.push('/login/storyboard');
                    }
                }
            }

            this.setState({
                context: this.context
            });
        }
    }

    refreshData() {
        storyboardRef.on("value", snapshot => {
            if (this.refs.storyboardTwo) {

                // this.testGraphDraw(snapshot.val());

                this.setState({
                    storyboardData: snapshot.val(),
                    isLoading: false
                });
            }
        });
    }

    testGraphDraw(data) {
        // graph TB
        // c1-->a2
        // subgraph one
        // a1-->a2
        // end
        // subgraph two
        // b1-->b2
        // end
        // subgraph three
        // c1-->c2
        // end

        var graphString = "graph TB";

        var count = 1;
        for (let chapterKey in data.content) {
            if (data.content.hasOwnProperty(chapterKey)) {
                let chapter = data.content[chapterKey];

                if (chapter.nodes != null) {

                    for (let nodeKey in chapter.nodes) {
                        if (chapter.nodes.hasOwnProperty(nodeKey)) {
                            const node = chapter.nodes[nodeKey];
                            // console.log("\t" + node.title);

                            graphString += "\n\t";
                            if (count > 1) {
                                graphString += "id" + (count - 1) + "-->";
                            }

                            var nodeTitle = node.title.trim();
                            nodeTitle = nodeTitle.replace("(", "*");
                            nodeTitle = nodeTitle.replace(")", "*");
                            graphString += "id" + count + "(" + nodeTitle + ")";
                            count++;
                        }
                    }
                }
            }
        }

        console.log(graphString);
    }

    showChapterEdit(chapter) {
        this.setState({
            showChapterEditView: true,
            showNodeEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: chapter,
            selectedNode: null,
            selectedNodeConnections: null
        });
    }

    onHideChapterEdit(insertInfo) {
        if (insertInfo != null) {
            if ("to" in insertInfo) {
                if (isNaN(insertInfo.to)) {
                    insertInfo.to = 0;
                }
            }

            if ("id" in insertInfo) {
                let updates = {};
                for (let chapterKey in this.state.storyboardData.content) {
                    if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                        let chapter = this.state.storyboardData.content[chapterKey];

                        if (chapterKey === insertInfo.id) {
                            updates["/" + chapterKey + "/index"] = insertInfo.to;
                        }
                        else {
                            if (insertInfo.to > insertInfo.from) {
                                if ((chapter.index > insertInfo.from) && (chapter.index <= insertInfo.to)) {
                                    updates["/" + chapterKey + "/index"] = chapter.index - 1;
                                }
                            }
                            else {
                                if ((chapter.index < insertInfo.from) && (chapter.index >= insertInfo.to)) {
                                    updates["/" + chapterKey + "/index"] = chapter.index + 1;
                                }
                            }
                        }
                    }
                }
                storyboardRef.child("content").update(updates);
            }
            else {
                let updates = {};
                for (let chapterKey in this.state.storyboardData.content) {
                    if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                        let chapter = this.state.storyboardData.content[chapterKey];
                        if (chapter.index >= insertInfo.to) {
                            updates["/" + chapterKey + "/index"] = chapter.index + 1;
                        }
                    }
                }
                storyboardRef.child("content").update(updates);

                let newChapterKey = storyboardRef.child('content').push().key;
                storyboardRef.child("content").child(newChapterKey).set({index: insertInfo.to, nodes: {}});
            }
        }

        this.setState({
            showChapterEditView: false,
        });
    }

    showNodeEdit(node) {
        let nodeConnections = null;
        if ((node) != null && ("id" in node)) {
            nodeConnections = [];
            if (this.state.storyboardData.connections != null) {
                if (node.id in this.state.storyboardData.connections) {
                    for (let nodeKey of this.state.storyboardData.connections[node.id]) {
                        for (let nodeInfo of this.currentNodes) {
                            if (nodeInfo.value === nodeKey) {
                                nodeConnections.push(nodeInfo);
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            showNodeEditView: true,
            showChapterEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: null,
            selectedNode: node,
            selectedNodeConnections: nodeConnections
        });
    }

    onHideNodeEdit(nodeInfo) {
        if (nodeInfo != null) {
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];

                    if ("previousChapter" in nodeInfo) {
                        if (chapter.index === nodeInfo.previousChapter) {
                            storyboardRef.child("content").child(chapterKey).child("nodes").child(nodeInfo.id).remove();
                        }
                    }

                    if (chapter.index === nodeInfo.chapterIndex) {
                        let specifiedChapterNodesRef = storyboardRef.child("content").child(chapterKey).child("nodes");

                        if ("id" in nodeInfo) {
                            specifiedChapterNodesRef.child(nodeInfo.id).set({
                                index: nodeInfo.index,
                                title: nodeInfo.title,
                                description: nodeInfo.description
                            });
                        }
                        else {
                            var nodeIndexInChapter = 0;
                            if ("nodes" in chapter) {
                                for (var key in chapter.nodes) {
                                    if (chapter.nodes.hasOwnProperty(key)) {
                                        nodeIndexInChapter++;
                                    }
                                }
                            }

                            let newNodeKey = storyboardRef.push().key;
                            nodeInfo.id = newNodeKey;
                            specifiedChapterNodesRef.child(newNodeKey).set({
                                index: nodeIndexInChapter,
                                title: nodeInfo.title,
                                description: nodeInfo.description
                            });
                        }
                    }
                }
            }

            if ("children" in nodeInfo) {
                storyboardRef.child("connections").child(nodeInfo.id).set(nodeInfo.children);
            }
        }

        this.setState({
            showNodeEditView: false
        });
    }

    showDeleteDialog(deletionData) {
        this.setState({
            showNodeEditView: false,
            showChapterEditView: false,
            showDeleteDialog: true,
            deletionData: deletionData,
            selectedChapter: null,
            selectedNode: null,
            selectedNodeConnections: null
        });
    }

    onDeleteCancel() {
        this.setState({
            showDeleteDialog: false
        });
    }

    onMoveNode(moveActionInfo) {
        if (moveActionInfo != null) {

            if (moveActionInfo.hasOwnProperty("type")) {

                var isLeftMove = true;
                if (moveActionInfo.type.toLocaleString() === "right") {
                    isLeftMove = false;
                }

                if (moveActionInfo.hasOwnProperty("target")) {
                    const targetNode = moveActionInfo.target;

                    let newIndexForSwapNode = targetNode.index;
                    var newIndexForTarget = targetNode.index - 1;
                    if (!isLeftMove) {
                        newIndexForTarget = targetNode.index + 1;
                    }

                    if (targetNode.hasOwnProperty("chapterData")) {

                        let chapter = targetNode.chapterData;

                        var swapNode = null;
                        if (chapter.nodes != null) {
                            for (let nodeId in chapter.nodes) {
                                if (chapter.nodes.hasOwnProperty(nodeId)) {
                                    let nodeToTest = chapter.nodes[nodeId];
                                    if (nodeToTest.hasOwnProperty("index")) {
                                        if (nodeToTest.index === newIndexForTarget) {
                                            swapNode = nodeToTest;
                                            swapNode.id = nodeId;
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        if (swapNode != null) {
                            let specifiedChapterNodesRef = storyboardRef.child("content").child(chapter.id).child("nodes");

                            let updatedDataChunk = {};
                            updatedDataChunk[`${targetNode.id}/index`] = newIndexForTarget;
                            updatedDataChunk[`${swapNode.id}/index`] = newIndexForSwapNode;

                            specifiedChapterNodesRef.update(updatedDataChunk);
                        }
                    }
                }
            }
        }
    }

    onDeleteConfirm(deletionData) {
        if (deletionData.type === "chapterDelete") {

            let updates = {};
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];
                    if (chapter.index > deletionData.target.index) {
                        updates["/" + chapterKey + "/index"] = chapter.index - 1;
                    }
                }
            }

            storyboardRef.child("content").update(updates);
            storyboardRef.child("content").child(deletionData.target.id).remove();
        }
        else if (deletionData.type === "nodeDelete") {
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];

                    if ("nodes" in chapter) {
                        if (deletionData.target.id in chapter.nodes) {
                            storyboardRef.child("content").child(chapterKey).child("nodes").child(deletionData.target.id).remove();
                        }
                    }
                }
            }

            storyboardRef.child("connections").child(deletionData.target.id).remove();

            for (let nodeKey in this.state.storyboardData.connections) {
                if (this.state.storyboardData.connections.hasOwnProperty(nodeKey)) {
                    for (let index in this.state.storyboardData.connections[nodeKey]) {
                        if (this.state.storyboardData.connections[nodeKey].hasOwnProperty(index)) {
                            let connectionKey = this.state.storyboardData.connections[nodeKey][index];
                            if (connectionKey === deletionData.target.id) {
                                storyboardRef.child("connections").child(nodeKey).child(index).remove();
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            showDeleteDialog: false
        });
    }

    generateConnectionData() {
        let colors = [
            'rgba(195, 0, 22, 1)',
            'rgba(56, 139, 237, 1)',
            'rgba(80, 227, 194, 1)',
            'rgba(126, 211, 33, 1)',
            'rgba(60, 230, 255, 1)',
            'rgba(0, 175, 109, 1)',
            'rgba(208, 2, 96, 1)',
            'rgba(255, 221, 120, 1)',
            'rgba(252, 152, 0, 1)',
            'rgba(255, 51, 209, 1)',
            'rgba(228, 228, 228, 1)',
            'rgba(0, 0, 0, 1)',
        ];
        var colorIndex = 0;
        let connectionColors = {};
        for (let nodeKey in this.state.storyboardData.connections) {
            if (this.state.storyboardData.connections.hasOwnProperty(nodeKey)) {
                let chapterId = this.getChapterIdForNode(nodeKey);
                if (chapterId != null) {
                    if (!connectionColors.hasOwnProperty(chapterId)) {
                        connectionColors[chapterId] = [];
                    }

                    var childConnectionIndex = 0;
                    for (let connectionKey in this.state.storyboardData.connections[nodeKey]) {
                        if (this.state.storyboardData.connections[nodeKey].hasOwnProperty(connectionKey)) {
                            let childNodeKey = this.state.storyboardData.connections[nodeKey][connectionKey];

                            let childChapterId = this.getChapterIdForNode(childNodeKey);
                            if (childChapterId != null) {

                                if (!connectionColors.hasOwnProperty(childChapterId)) {
                                    connectionColors[childChapterId] = [];
                                }

                                var childConnectionData = {};
                                childConnectionData.parentColors = [];
                                childConnectionData.childColors = [];

                                if (connectionColors[chapterId].hasOwnProperty(nodeKey)) {
                                    childConnectionData = connectionColors[chapterId][nodeKey];
                                }

                                var fromNode = null;
                                let parentChapter = this.state.storyboardData.content[chapterId];
                                if (parentChapter.nodes != null) {
                                    if (parentChapter.nodes.hasOwnProperty(nodeKey)) {
                                        fromNode = parentChapter.nodes[nodeKey];
                                        fromNode.chapterIndex = parentChapter.index;
                                    }
                                }

                                var toNode = null;
                                let childChapter = this.state.storyboardData.content[childChapterId];
                                if (childChapter.nodes != null) {
                                    if (childChapter.nodes.hasOwnProperty(childNodeKey)) {
                                        toNode = childChapter.nodes[childNodeKey];
                                        toNode.chapterIndex = childChapter.index;
                                    }
                                }

                                var connectionIndex = (colorIndex + 1) + String.fromCharCode(97 + childConnectionIndex);
                                childConnectionData.childColors.push({color: colors[colorIndex], index: connectionIndex, fromNode: toNode});
                                connectionColors[chapterId][nodeKey] = childConnectionData;

                                var parentConnectionData = {};
                                parentConnectionData.parentColors = [];
                                parentConnectionData.childColors = [];

                                if (connectionColors[childChapterId].hasOwnProperty(childNodeKey)) {
                                    parentConnectionData = connectionColors[childChapterId][childNodeKey];
                                }

                                parentConnectionData.parentColors.push({color: colors[colorIndex], index: connectionIndex, toNode: fromNode});
                                connectionColors[childChapterId][childNodeKey] = parentConnectionData;
                            }
                        }

                        childConnectionIndex++;
                    }
                }

                colorIndex++;
                if (colorIndex >= colors.length) {
                    colorIndex = 0;
                }
            }
        }

        return connectionColors;
    }

    generateStoryboard() {
        if (this.state.storyboardData == null) {
            return (<div className="storyboardTwo">Could not load data <a href="/storyboardbranches">Please select a different branch</a></div>);
        }
        else if (!("content" in this.state.storyboardData)) {
            return (<div className="storyboardTwo">Empty Storyboard</div>);
        }

        let nodeCoordinates = {};
        let chapterData = [];
        let maxNodesInChapter = 0;
        let nodeList = [];
        for (let chapterKey in this.state.storyboardData.content) {
            if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                let chapter = this.state.storyboardData.content[chapterKey];

                if (chapter.nodes != null) {
                    let nodesInChapter = Object.keys(chapter.nodes).length;
                    if (nodesInChapter > maxNodesInChapter) {
                        maxNodesInChapter = nodesInChapter;
                    }

                    let nodeIndex = 0;
                    for (let node in chapter.nodes) {
                        if (chapter.nodes.hasOwnProperty(node)) {
                            nodeCoordinates[node] = {chapter: chapter.index, node: nodeIndex};
                            nodeIndex++;
                            nodeList.push({value: node, label: chapter.nodes[node].title, chapter: chapter.index});
                        }
                    }
                }

                let chapterCopy = JSON.parse(JSON.stringify(chapter));
                chapterCopy.id = chapterKey;
                chapterData.push(chapterCopy);
            }
        }

        this.currentNodes = nodeList;

        let connectionColors = this.generateConnectionData();

        chapterData = chapterData.sort((a, b) => {return (b.index < a.index) ? 1 : (b.index > a.index) ? -1 : 0;});
        let chapters = [];
        for (let chapter of chapterData) {
            chapters.push(<ChapterTwo onChapterSelect={() => {this.showChapterEdit(chapter)}}
                                      nodeDeleteAction={this.showDeleteDialog}
                                      nodeSelectAction={this.showNodeEdit}
                                      nodeMoveAction={this.onMoveNode}
                                      onDelete={() => {this.showDeleteDialog({type: "chapterDelete", target: chapter})}}
                                      index={chapter.index}
                                      key={chapter.index}
                                      title={"Chapter " + (chapter.index + 1)}
                                      nodeData={chapter.nodes}
                                      chapterData={chapter}
                                      connectionColors={connectionColors[chapter.id]}/>);
        }

        return (<div className="storyboardTwo">{chapters}</div>);
    }

    getChapterIdForNode(chapterId) {
        for (let chapterKey in this.state.storyboardData.content) {
            if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                let chapter = this.state.storyboardData.content[chapterKey];

                if ("nodes" in chapter) {
                    if (chapter.nodes.hasOwnProperty(chapterId)) {
                        return chapterKey;
                    }
                }
            }
        }

        return null;
    }

    render() {
        let tabData = [
            {title: "Add Chapter", action: () => {this.showChapterEdit(null)}},
            {title: "Add Node", action: () => {this.showNodeEdit(null)}}
        ];

        let storyboardRedirect = "/storyboardhorizontal";
        if (this.props.params.id != null) {
            storyboardRedirect = storyboardRedirect + "/" + this.props.params.id;
        }

        let rightOptions = [
            {title: "Horizontal", action: () => {browserHistory.push(storyboardRedirect)}},
            {title: "Branches", action: () => {browserHistory.push('/storyboardbranches')}},
        ];

        let numberOfChapters = 0;
        if (this.state.storyboardData != null) {
            if ("content" in this.state.storyboardData) {
                numberOfChapters = Object.keys(this.state.storyboardData.content).length;
            }
        }

        return (
            <div ref="storyboardTwo" className="storyboardTwoContent">
                <MenuBar tabData={tabData} rightOptions={rightOptions}/>
                <div className="storyboardTwoContainer">
                    <div className={this.state.isLoading ? "activityIndicator" : "activityIndicatorHidden"}/>
                    {this.generateStoryboard()}
                </div>
                <ChapterEditView show={this.state.showChapterEditView} selectedChapter={this.state.selectedChapter} numberOfChapters={numberOfChapters} onDone={this.onHideChapterEdit} />
                <NodeEditView show={this.state.showNodeEditView} selectedNode={this.state.selectedNode} numberOfChapters={numberOfChapters} onDone={this.onHideNodeEdit} availableNodes={this.currentNodes} selectedConnections={this.state.selectedNodeConnections} />
                <DeleteDialog show={this.state.showDeleteDialog} deletionData={this.state.deletionData} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
            </div>
        );
    }
}

export default StoryboardTwo;
