/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './chapter.css';
import Node from './node.js'
import deleteIcon from '../../resources/deleteIcon.svg';

class Chapter extends Component {

    constructor() {
        super();

        this.generateNodes = this.generateNodes.bind(this);
    }

    generateNodes() {
        let nodes = [];
        for (let nodeKey in this.props.nodeData) {

            if (this.props.nodeData.hasOwnProperty(nodeKey)) {
                let node = this.props.nodeData[nodeKey];

                let nodeInfo = {
                    title: node.title,
                    description: node.description,
                    chapter: this.props.index,
                    id: nodeKey
                };

                nodes.push(<Node key={node.title}
                                 title={node.title}
                                 description={node.description}
                                 editAction={() => {
                                     this.props.nodeSelectAction(nodeInfo)
                                 }}
                                 index={node.index}
                                 deleteAction={() => {
                                     this.props.nodeDeleteAction({type: "nodeDelete", target: nodeInfo})
                                 }}/>);
            }
        }

        let sortedNodes = nodes.sort((a, b) => {return (b.props.index < a.props.index) ? 1 : (b.props.index > a.props.index) ? -1 : 0;});

        return sortedNodes;
    }

    render() {

        let deleteButton = <div />;
        if ((this.props.nodeData == null) || (this.props.nodeData.length === 0)) {
            deleteButton = <img src={deleteIcon} className="chapterDeleteIcon" alt="delete" onClick={this.props.onDelete}/>;
        }

        return (
            <div className="chapterBlock">
                <div className="chapterHeader">
                    {deleteButton}
                    <div className="chapterTitleLabel" onClick={this.props.onChapterSelect}>{this.props.title}</div>
                </div>
                {this.generateNodes()}
            </div>
        );
    }
}

export default Chapter;