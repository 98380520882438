// Import FirebaseAuth and firebase.
import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import './login.css';
import DevPortalContext from '../devPortal/Context/DevPortalContext.js';
import {browserHistory} from "react-router";

// Initialize Firebase
// let config = {
//     apiKey: "AIzaSyANHZu0buZ2VhmO4EIhdJZNSsWEYiPidhg",
//     authDomain: "axadal-624dd.firebaseapp.com",
//     databaseURL: "https://axadal-624dd.firebaseio.com",
//     storageBucket: "axadal-624dd.appspot.com",
//     messagingSenderId: "763672656949"
// };
// firebase.initializeApp(config);

class Login extends Component {

    static contextType = DevPortalContext;

    constructor(props) {
        super(props);

        // Configure FirebaseUI.
        let uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            signInSuccessUrl: '/devportal',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ]
        };

        console.log(this.props.params);

        if (this.props.params.redirectPath != null) {
            uiConfig.signInSuccessUrl = `/${this.props.params.redirectPath}`;
            if (this.props.params.redirectPathTwo != null) {
                uiConfig.signInSuccessUrl = `/${this.props.params.redirectPath}/${this.props.params.redirectPathTwo}`;
            }
        }

        this.state = {
            uiConfig: uiConfig,
            context: {}
        };
    }

    componentDidMount() {
        console.log(this.context);
        if (this.context.user != null) {
            browserHistory.push(this.state.uiConfig.signInSuccessUrl);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context !== this.state.context) {
            if (this.context.user != null) {
                browserHistory.push(this.state.uiConfig.signInSuccessUrl);
            }

            this.setState({
                context: this.context
            });
        }
    }

    render() {
        return (
            <div className="loginView">
                <h1>Dev Portal</h1>
                <p>Please sign-in to view/edit:</p>
                <p>(Only specific users will be granted write access)</p>
                <StyledFirebaseAuth uiConfig={this.state.uiConfig} firebaseAuth={firebase.auth()}/>
            </div>
        );
    }
}

export default Login;