import React, { Component } from 'react';
import './gameTester.css';
import MenuBar from './menuBar';
import {browserHistory} from "react-router";

const DELAY = 750;
let axios = require('axios');
let https = require('https');

class App extends Component {

    constructor() {
        super();

        this.state = {
            nextMessageIndexInSection: 0,
            sectionIndex: 0,
            messages: [],
            currentOptions: [],
            gameData: {},
            isLoading: true
        };

        this.loadGame = this.loadGame.bind(this);
        this.showNextMessage = this.showNextMessage.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.generateMessageConsole = this.generateMessageConsole.bind(this);
        this.goToSection = this.goToSection.bind(this);
    }

    loadGame(gameData) {
        this.setState({
            gameData: gameData,
            isLoading: false
        });
    }

    componentDidMount() {
        let agent = new https.Agent({
            rejectUnauthorized: false
        });

        axios.get("https://picketcats.com:55555/gamedata", {
            httpsAgent: agent
        })
        .then((response) => {
            this.loadGame(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.gameData !== this.state.gameData) {
            this.showNextMessage();
        }
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = null;
        }
    }

    renderOptions(options) {
        let buttons = [];
        for (let option of options) {
            buttons.push(<button key={option.text} onClick={() => this.goToSection(option.destination, option.text)}>{option.text}</button>)
        }

        return <div>{buttons}</div>;
    }

    showNextMessage() {
        this.timerHandle = setTimeout(() => {
            var currentSection = this.state.gameData.sections[this.state.sectionIndex];

            if (currentSection.messages.length > this.state.nextMessageIndexInSection) {
                var newMessages = this.state.messages;
                var messageData = currentSection.messages[this.state.nextMessageIndexInSection];
                if (messageData.delay > 0) {
                    messageData.text = "[WAIT " + messageData.delay + "]: " + messageData.text;
                }
                newMessages.push(messageData);

                let newNextMessageIndexInSection = this.state.nextMessageIndexInSection + 1;

                this.setState({
                    nextMessageIndexInSection: newNextMessageIndexInSection,
                    messages: newMessages
                });

                this.showNextMessage();
            }
            else {
                var shouldGoToNextSectionAutomatically = false;
                if (currentSection.options.length === 1) {
                    if (currentSection.options[0].text == null) {
                        shouldGoToNextSectionAutomatically = true;
                        let nextSectionIndex = currentSection.options[0].destination;
                        this.goToSection(nextSectionIndex, null);
                    }
                }

                if (!shouldGoToNextSectionAutomatically) {
                    this.setState({
                        currentOptions: currentSection.options
                    });
                }
            }
        }, DELAY);
    }

    goToSection(sectionIndex, optionText) {
        let destinationIndex = -1;
        for (var i = 0; i < this.state.gameData.sections.length; i++) {
            let section = this.state.gameData.sections[i];
            if (section.id === sectionIndex) {
                destinationIndex = i;
            }
        }

        let newMessages = this.state.messages;
        if (destinationIndex > -1) {
            if (optionText != null) {
                let messageData = {text: "<< " + optionText + " >>", delay: 0};
                newMessages.push(messageData);
            }

            this.setState({
                sectionIndex: destinationIndex,
                messages: newMessages,
                nextMessageIndexInSection: 0,
                currentOptions: []
            });

            this.showNextMessage();
        }
        else {
            let messageData = {text: "<< CANNOT FIND CONTENT FOR SELECTED OPTION >>", delay: 0};
            newMessages.push(messageData);

            this.setState({
                messages: newMessages
            });
        }
    }

    generateMessageConsole() {
        var messageComponents = [];

        for (var messageIndex in this.state.messages) {
            if (this.state.messages.hasOwnProperty(messageIndex)) {
                let message = this.state.messages[messageIndex];
                messageComponents.push(<div key={"key" + messageIndex}>{message.text}</div>)
            }
        }

        return messageComponents;
    }

    render() {
        let tabData = [
            {title: "Home", action: ()=>{browserHistory.push('/')}},
            {title: "Dev Portal", action: ()=>{browserHistory.push('/devportal')}}
        ];

        return (
            <div className="GameTester">
                <MenuBar tabData={tabData}/>
                <div className="Console">
                    <div className={this.state.isLoading ? "activityIndicator" : "activityIndicatorHidden"}/>
                    <div className="ConsoleText">{this.generateMessageConsole()}</div>
                    {this.renderOptions(this.state.currentOptions)}
                </div>
                <div className="Footer">
                    <input className="SectionIdInput" onSubmit={() => this.goToSection(this.refs.sectionField.value, "User Jumped to section")} ref="sectionField" placeholder="Section ID"/>
                    <button onClick={() => this.goToSection(this.refs.sectionField.value, "User Jumped to section")}>Go</button>
                </div>
            </div>
        );
    }
}

export default App;
