/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './node.css';
import deleteIcon from '../../resources/deleteIcon.svg';

class Node extends Component {

    constructor() {
        super();

        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(event) {
        event.stopPropagation();

        this.props.deleteAction();
    }

    render() {
        return (
            <div className="node">
                <div className="nodeTitle" onClick={this.props.editAction}>
                    {this.props.title}
                    <img src={deleteIcon} className="nodeDeleteIcon" alt="delete" onClick={this.onDelete}/>
                </div>
                <div className="nodeDescription">
                    {this.props.description}
                </div>
                <div className="extraLineObscurer"/>
            </div>
        );
    }
}

export default Node;