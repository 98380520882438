/**
 * Created by davidcaddy on 31/01/2017.
 */

import React, { Component } from 'react';
import './deleteDialog.css';

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class DeleteDialog extends Component {

    constructor() {
        super();

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
    }

    componentDidMount() {

    }

    onBackgroundViewClick(event) {
        this.props.onCancel();
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        let dialog = "Are you sure you want to delete?";

        if (this.props.deletionData != null) {
            if (this.props.deletionData.type === "chapterDelete") {
                dialog = "Are you sure you want to delete chapter " + this.props.deletionData.target.index + "?";
            }
            else if (this.props.deletionData.type === "nodeDelete") {
                dialog = "Are you sure you want to delete node \"" + this.props.deletionData.target.title + "\"?";
            }
        }


        return(
            <div className="deleteDialogBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <div className="deleteDialogFrame" onClick={this.onFrameClick}>
                    {dialog}
                    <div>
                        <button className="deleteButton" onClick={() => this.props.onConfirm(this.props.deletionData)}>Delete</button>
                        <button className="cancelButton" onClick={this.props.onCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteDialog;