/**
 * Created by davidcaddy on 29/01/2017.
 */

import React, { Component } from 'react';
import './App.css';
import batLogo from './resources/AXADALBatWhite.svg';
import {browserHistory} from 'react-router';
import MenuBar from './components/menuBar';

class Header extends Component {
    render() {

        let tabData = [
            {title: "Games", action: ()=>{browserHistory.push('/')}},
            {title: "The Team", action: ()=>{browserHistory.push('/team')}},
            {title: "About", action: ()=>{browserHistory.push('/about')}}
        ];

        let rightTabData = [
            {title: "Dev Portal", action: ()=>{browserHistory.push('/devportal')}},
        ];

        return (
            <div>
                <div className="AppHeader" onClick={()=>{browserHistory.push('/')}}>
                    <img src={batLogo} className="AppLogo" alt="logo" onClick={()=>{browserHistory.push('/')}}/>
                    <h2 className="AppTitle"><i>AXADAL</i></h2>
                </div>
                <div className="headerMenuSpacer"/>
                <MenuBar tabData={tabData} rightOptions={rightTabData}/>
                <div className="Content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Header;