/**
 * Created by david on 23/01/2017.
 */

import React, { Component } from 'react';
import './menuBar.css'

class MenuBar extends Component {
    render() {
        let tabs = [];
        for (let i = 0; i < this.props.tabData.length; i++) {
            tabs.push(<div className="tab" key={this.props.tabData[i].title}
                           onClick={this.props.tabData[i].action}>{this.props.tabData[i].title}</div>)
            if (i !== (this.props.tabData.length - 1)) {
                tabs.push(<div className="spacer" key={"spacer" + i}/>);
            }
        }

        let rightTabs = [];
        if (this.props.rightOptions != null) {
            for (let i = 0; i < this.props.rightOptions.length; i++) {
                rightTabs.push(<div className="rightTab" key={this.props.rightOptions[i].title}
                                    onClick={this.props.rightOptions[i].action}>{this.props.rightOptions[i].title}</div>)
                if (i !== (this.props.rightOptions.length - 1)) {
                    rightTabs.push(<div className="rightSpacer" key={"rSpacer" + i}/>);
                }
            }
        }

        return (
        <div className="container">
            {tabs}
            <div className="rightTabContainer"/>
                {rightTabs}
            <div/>
        </div>
        );
    }
}

export default MenuBar;

