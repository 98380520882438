import React, { Component } from 'react';
import './DevPortalLandingPage.css';
import {browserHistory} from "react-router";
import MenuBar from "../components/menuBar";

class DevPortalLandingPage extends Component {
    render() {
        let tabData = [
            {title: "Home", action: ()=>{browserHistory.push('/')}}
        ];

        return (
            <div className="DevPortalLandingPage">
                <MenuBar tabData={tabData}/>
                <div className="DevPortalLandingPageBody">
                    <div className="DevPortalLandingPageButtonContainer">
                        <button className="DevPortalLandingPageButton" onClick={()=>{browserHistory.push('/storyboardbranches')}}>Storyboard</button>
                        <button className="DevPortalLandingPageButton" onClick={()=>{browserHistory.push('/projectaxolotl')}}>Game Tester</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DevPortalLandingPage;