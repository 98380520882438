/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './storyboard.css';
import Chapter from './chapter.js';
import Connections from './connections.js';
import MenuBar from '../../components/menuBar.js';
import ChapterEditView from './chapterEditView.js'
import NodeEditView from './nodeEditView.js'
import DeleteDialog from './deleteDialog.js'
import {browserHistory} from "react-router";
import DevPortalContext from '../Context/DevPortalContext.js';
import firebase from 'firebase/app';
import 'firebase/database';
import StoryboardTwo from "../storyboardTwo/storyboardTwo";

// Initialize Firebase
// let config = {
//     apiKey: "AIzaSyANHZu0buZ2VhmO4EIhdJZNSsWEYiPidhg",
//     authDomain: "axadal-624dd.firebaseapp.com",
//     databaseURL: "https://axadal-624dd.firebaseio.com",
//     storageBucket: "axadal-624dd.appspot.com",
//     messagingSenderId: "763672656949"
// };
// firebase.initializeApp(config);


const rootRef = firebase.database().ref();
const rootStoryboardRef = rootRef.child("storyboardData").child("branches");
let storyboardRef = rootStoryboardRef.child("branch1");

StoryboardTwo.contextType = DevPortalContext;

class Storyboard extends Component {

    static contextType = DevPortalContext;

    constructor(props) {
        super(props);

        this.state = {
            showChapterEditView: false,
            showNodeEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: null,
            selectedNode: null,
            storyboardData: {content: {}, connections: {}},
            isLoading: true,
            context: {}
        };

        if (this.props.params.id != null) {
            storyboardRef = rootStoryboardRef.child(this.props.params.id);
        }

        this.currentNodes = [];

        this.generateStoryboard = this.generateStoryboard.bind(this);
        this.generateConnections = this.generateConnections.bind(this);
        this.showChapterEdit = this.showChapterEdit.bind(this);
        this.onHideChapterEdit = this.onHideChapterEdit.bind(this);
        this.showNodeEdit = this.showNodeEdit.bind(this);
        this.onHideNodeEdit = this.onHideNodeEdit.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        if (this.context.user != null) {
            this.refreshData();
        }

        if (this.props.params.id == null) {
            browserHistory.push('/login/storyboardbranches');
        }

        if (!this.context.isFetchingUser && this.context.user == null) {
            if (this.props.params.id != null) {
                browserHistory.push(`/login/storyboardhorizontal/${this.props.params.id}`);
            }
            else {
                browserHistory.push('/login/storyboardhorizontal');
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context !== this.state.context) {
            if (this.context.user != null) {
                this.refreshData();
            }
            else {
                if (!this.context.isFetchingUser) {
                    if (this.props.params.id != null) {
                        browserHistory.push(`/login/storyboardhorizontal/${this.props.params.id}`);
                    }
                    else {
                        browserHistory.push('/login/storyboardhorizontal');
                    }
                }
            }

            this.setState({
                context: this.context
            });
        }
    }

    refreshData() {
        storyboardRef.on("value", snapshot => {
            if (this.refs.storyboard) {
                this.setState({
                    storyboardData: snapshot.val(),
                    isLoading: false
                });
            }
        });
    }

    showChapterEdit(chapter) {
        this.setState({
            showChapterEditView: true,
            showNodeEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: chapter,
            selectedNode: null,
            selectedNodeConnections: null
        });
    }

    onHideChapterEdit(insertInfo) {
        if (insertInfo != null) {
            if ("to" in insertInfo) {
                if (isNaN(insertInfo.to)) {
                    insertInfo.to = 0;
                }
            }

            if ("id" in insertInfo) {

                let updates = {};
                for (let chapterKey in this.state.storyboardData.content) {
                    if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                        let chapter = this.state.storyboardData.content[chapterKey];

                        if (chapterKey === insertInfo.id) {
                            updates["/" + chapterKey + "/index"] = insertInfo.to;
                        }
                        else {
                            if (insertInfo.to > insertInfo.from) {
                                if ((chapter.index > insertInfo.from) && (chapter.index <= insertInfo.to)) {
                                    updates["/" + chapterKey + "/index"] = chapter.index - 1;
                                }
                            }
                            else {
                                if ((chapter.index < insertInfo.from) && (chapter.index >= insertInfo.to)) {
                                    updates["/" + chapterKey + "/index"] = chapter.index + 1;
                                }
                            }
                        }
                    }
                }
                storyboardRef.child("content").update(updates);
            }
            else {
                let updates = {};
                for (let chapterKey in this.state.storyboardData.content) {
                    if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                        let chapter = this.state.storyboardData.content[chapterKey];
                        if (chapter.index >= insertInfo.to) {
                            updates["/" + chapterKey + "/index"] = chapter.index + 1;
                        }
                    }
                }
                storyboardRef.child("content").update(updates);

                let newChapterKey = storyboardRef.child('content').push().key;
                storyboardRef.child("content").child(newChapterKey).set({index: insertInfo.to, nodes: {}});
            }
        }

        this.setState({
            showChapterEditView: false,
        });
    }

    showNodeEdit(node) {

        let nodeConnections = null;
        if ((node) != null && ("id" in node)) {
            nodeConnections = [];
            if (this.state.storyboardData.connections != null) {
                if (node.id in this.state.storyboardData.connections) {
                    for (let nodeKey of this.state.storyboardData.connections[node.id]) {
                        for (let nodeInfo of this.currentNodes) {
                            if (nodeInfo.value === nodeKey) {
                                nodeConnections.push(nodeInfo);
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            showNodeEditView: true,
            showChapterEditView: false,
            showDeleteDialog: false,
            deletionData: null,
            selectedChapter: null,
            selectedNode: node,
            selectedNodeConnections: nodeConnections
        });
    }

    onHideNodeEdit(nodeInfo) {

        if (nodeInfo != null) {
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];

                    if ("previousChapter" in nodeInfo) {
                        if (chapter.index === nodeInfo.previousChapter) {
                            storyboardRef.child("content").child(chapterKey).child("nodes").child(nodeInfo.id).remove();
                        }
                    }

                    if (chapter.index === nodeInfo.chapter) {
                        let specifiedChapterNodesRef = storyboardRef.child("content").child(chapterKey).child("nodes");

                        if ("id" in nodeInfo) {
                            specifiedChapterNodesRef.child(nodeInfo.id).set({
                                index: nodeInfo.index,
                                title: nodeInfo.title,
                                description: nodeInfo.description
                            });
                        }
                        else {
                            var nodeIndexInChapter = 0;
                            if ("nodes" in chapter) {
                                for (var key in chapter.nodes) {
                                    if (chapter.nodes.hasOwnProperty(key)) {
                                        nodeIndexInChapter++;
                                    }
                                }
                            }

                            let newNodeKey = storyboardRef.push().key;
                            nodeInfo.id = newNodeKey;
                            specifiedChapterNodesRef.child(newNodeKey).set({
                                index: nodeIndexInChapter,
                                title: nodeInfo.title,
                                description: nodeInfo.description
                            });
                        }
                    }
                }
            }

            if ("children" in nodeInfo) {
                storyboardRef.child("connections").child(nodeInfo.id).set(nodeInfo.children);
            }
        }

        this.setState({
            showNodeEditView: false
        });
    }

    showDeleteDialog(deletionData) {
        this.setState({
            showNodeEditView: false,
            showChapterEditView: false,
            showDeleteDialog: true,
            deletionData: deletionData,
            selectedChapter: null,
            selectedNode: null,
            selectedNodeConnections: null
        });
    }

    onDeleteCancel() {
        this.setState({
            showDeleteDialog: false
        });
    }

    onDeleteConfirm(deletionData) {

        if (deletionData.type === "chapterDelete") {

            let updates = {};
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];
                    if (chapter.index > deletionData.target.index) {
                        updates["/" + chapterKey + "/index"] = chapter.index - 1;
                    }
                }
            }

            storyboardRef.child("content").update(updates);
            storyboardRef.child("content").child(deletionData.target.id).remove();
        }
        else if (deletionData.type === "nodeDelete") {
            for (let chapterKey in this.state.storyboardData.content) {
                if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                    let chapter = this.state.storyboardData.content[chapterKey];

                    if ("nodes" in chapter) {
                        if (deletionData.target.id in chapter.nodes) {
                            storyboardRef.child("content").child(chapterKey).child("nodes").child(deletionData.target.id).remove();
                        }
                    }
                }
            }

            storyboardRef.child("connections").child(deletionData.target.id).remove();

            for (let nodeKey in this.state.storyboardData.connections) {
                if (this.state.storyboardData.connections.hasOwnProperty(nodeKey)) {
                    for (let index in this.state.storyboardData.connections[nodeKey]) {
                        if (this.state.storyboardData.connections[nodeKey].hasOwnProperty(index)) {
                            let connectionKey = this.state.storyboardData.connections[nodeKey][index];
                            if (connectionKey === deletionData.target.id) {
                                storyboardRef.child("connections").child(nodeKey).child(index).remove();
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            showDeleteDialog: false
        });
    }

    generateConnections(width, height, nodeCoordinates) {

        let colors = [
            'rgba(195, 0, 22, 1)',
            'rgba(56, 139, 237, 1)',
            'rgba(80, 227, 194, 1)',
            'rgba(126, 211, 33, 1)',
            'rgba(60, 230, 255, 1)',
            'rgba(0, 175, 109, 1)',
            'rgba(208, 2, 96, 1)',
            'rgba(255, 221, 120, 1)',
            'rgba(252, 152, 0, 1)',
            'rgba(255, 51, 209, 1)',
            'rgba(228, 228, 228, 1)',
            'rgba(0, 0, 0, 1)',
        ];

        let connections = [];

        let xOffset = 270;
        let yOffset = 62;

        let colorIndex = 0;
        for (let nodeKey in this.state.storyboardData.connections) {
            if (this.state.storyboardData.connections.hasOwnProperty(nodeKey)) {
                let startNodeCoordinate = nodeCoordinates[nodeKey];
                let numberOfChildren = Object.keys(this.state.storyboardData.connections[nodeKey]).length;
                let childIndex = 0;
                for (let connectionKey in this.state.storyboardData.connections[nodeKey]) {
                    if (this.state.storyboardData.connections[nodeKey].hasOwnProperty(connectionKey)) {
                        let childNodeKey = this.state.storyboardData.connections[nodeKey][connectionKey];
                        let endNodeCoordinate = nodeCoordinates[childNodeKey];
                        let staggerYOffset = 0;
                        if (numberOfChildren > 1) {
                            staggerYOffset = ((70 / (numberOfChildren - 1)) * childIndex);
                        }
                        let tailPoint = {
                            x: (xOffset + (startNodeCoordinate.chapter * 290)),
                            y: (yOffset + staggerYOffset + (startNodeCoordinate.node * 118))
                        };
                        let headPoint = {
                            x: (xOffset + 30 + ((endNodeCoordinate.chapter - 1) * 290)),
                            y: (yOffset + 30 + (endNodeCoordinate.node * 118))
                        };

                        connections.push({head: headPoint, tail: tailPoint, color: colors[colorIndex]});
                        childIndex++;
                    }
                }

                colorIndex++;
                if (colorIndex >= colors.length) {
                    colorIndex = 0;
                }
            }
        }

        return <Connections connections={connections} width={width} height={height}/>;
    }


    generateStoryboard() {
        if (this.state.storyboardData == null) {
            return (<div className="storyboardTwo">Could not load data</div>);
        }
        else if (!("content" in this.state.storyboardData)) {
            return (<div className="storyboardTwo">Empty Storyboard</div>);
        }

        let nodeCoordinates = {};
        let chapterData = [];
        let maxNodesInChapter = 0;
        let nodeList = [];
        for (let chapterKey in this.state.storyboardData.content) {
            if (this.state.storyboardData.content.hasOwnProperty(chapterKey)) {
                let chapter = this.state.storyboardData.content[chapterKey];

                if (chapter.nodes != null) {
                    let nodesInChapter = Object.keys(chapter.nodes).length;
                    if (nodesInChapter > maxNodesInChapter) {
                        maxNodesInChapter = nodesInChapter;
                    }

                    let nodeIndex = 0;
                    for (let node in chapter.nodes) {
                        if (chapter.nodes.hasOwnProperty(node)) {
                            nodeCoordinates[node] = {chapter: chapter.index, node: nodeIndex};
                            nodeIndex++;
                            nodeList.push({value: node, label: chapter.nodes[node].title, chapter: chapter.index});
                        }
                    }
                }

                let chapterCopy = JSON.parse(JSON.stringify(chapter));
                chapterCopy.id = chapterKey;
                chapterData.push(chapterCopy);
            }
        }

        this.currentNodes = nodeList;

        chapterData = chapterData.sort((a, b) => {return (b.index < a.index) ? 1 : (b.index > a.index) ? -1 : 0;});
        let chapters = [];
        for (let chapter of chapterData) {
            chapters.push(<Chapter onChapterSelect={() => {this.showChapterEdit(chapter)}}
                                   nodeDeleteAction={this.showDeleteDialog}
                                   nodeSelectAction={this.showNodeEdit}
                                   onDelete={() => {this.showDeleteDialog({type: "chapterDelete", target: chapter})}}
                                   index={chapter.index}
                                   key={chapter.index}
                                   title={"Chapter " + (chapter.index + 1)}
                                   nodeData={chapter.nodes}/>);
        }

        let numberOfChapters = Object.keys(this.state.storyboardData.content).length;

        let width = (numberOfChapters * 280) + ((numberOfChapters - 1) * 10);
        let height = (108 * maxNodesInChapter) + ((maxNodesInChapter + 1) * 10) + 24;

        let connections = this.generateConnections(width, height, nodeCoordinates);

        return (<div className="storyboard" style={{"width": width}}>{connections}{chapters}</div>);
    }

    render() {

        let tabData = [
            {title: "Add Chapter", action: () => {this.showChapterEdit(null)}},
            {title: "Add Node", action: () => {this.showNodeEdit(null)}}
        ];

        let storyboardRedirect = "/storyboard";
        if (this.props.params.id != null) {
            storyboardRedirect = storyboardRedirect + "/" + this.props.params.id;
        }

        let rightOptions = [
            {title: "Vertical", action: ()=>{browserHistory.push(storyboardRedirect)}},
            {title: "Branches", action: () => {browserHistory.push('/storyboardbranches')}},
        ];

        let numberOfChapters = 0;
        if (this.state.storyboardData != null) {
            if ("content" in this.state.storyboardData) {
                numberOfChapters = Object.keys(this.state.storyboardData.content).length;
            }
        }

        return (
            <div ref="storyboard" className="storyboardContent">
                <MenuBar tabData={tabData} rightOptions={rightOptions}/>
                <div className="storyboardContainer">
                    <div className={this.state.isLoading ? "activityIndicator" : "activityIndicatorHidden"}/>
                    {this.generateStoryboard()}
                </div>
                <ChapterEditView show={this.state.showChapterEditView} selectedChapter={this.state.selectedChapter} numberOfChapters={numberOfChapters} onDone={this.onHideChapterEdit} />
                <NodeEditView show={this.state.showNodeEditView} selectedNode={this.state.selectedNode} numberOfChapters={numberOfChapters} onDone={this.onHideNodeEdit} availableNodes={this.currentNodes} selectedConnections={this.state.selectedNodeConnections} />
                <DeleteDialog show={this.state.showDeleteDialog} deletionData={this.state.deletionData} onConfirm={this.onDeleteConfirm} onCancel={this.onDeleteCancel} />
            </div>
        );
    }
}

export default Storyboard;
