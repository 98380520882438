/**
 * Created by davidcaddy on 29/01/2017.
 */

import React, { Component } from 'react';
import './chapterEditView.css';
import deleteIconDark from '../../resources/deleteIconDark.svg';

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class ChapterEditView extends Component {

    constructor() {
        super();

        this.state = {
            chapterDestination: 0
        };

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.onInsert = this.onInsert.bind(this);
        this.onChapterSelect = this.onChapterSelect.bind(this);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.selectedChapter != null) && ("index" in nextProps.selectedChapter)) {
            this.setState({
                chapterDestination: nextProps.selectedChapter.index
            });
        }
        else {
            this.setState({
                chapterDestination: nextProps.numberOfChapters
            });
        }
    }

    onBackgroundViewClick(event) {
        this.props.onDone();
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    onInsert(event) {
        if (this.props.selectedChapter != null) {
            let insertInfo = {id: this.props.selectedChapter.id, from: this.props.selectedChapter.index, to:parseInt(this.refs.chapterSelect.value, 10)};
            this.props.onDone(insertInfo);
        }
        else {
            this.props.onDone({to: parseInt(this.refs.chapterSelect.value, 10)});
        }
    }

    onChapterSelect(event) {
        this.setState({chapterDestination: event.target.value});
    }

    render() {

        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        let options = [];

        let buttonText = "Add new chapter";
        let indexSelectLabelText = "Insert after:";
        if (this.props.selectedChapter != null) {
            buttonText = "Move chapter " + (this.props.selectedChapter.index + 1);
            indexSelectLabelText = "Move to:";
            for (let i = this.props.numberOfChapters; i >= 1; i--) {
                options.push(<option key={"c" + i} value={(i - 1)}>{"Chapter " + i}</option>);
            }
        }
        else {
            for (let i = (this.props.numberOfChapters - 1); i >= 0; i--) {
                options.push(<option key={"c" + i} value={(i + 1)}>{"Chapter " + (i + 1)}</option>);
            }
        }

        return(
            <div className="chapterEditBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <div className="chapterEditViewFrame" onClick={this.onFrameClick}>
                    <img src={deleteIconDark} className="chapterEditCancelIcon" alt="cancel" onClick={this.onBackgroundViewClick} />
                    <div className="indexSelectLabel">{indexSelectLabelText}</div>
                    <select value={this.state.chapterDestination} ref="chapterSelect" className="indexSelect" onChange={this.onChapterSelect}>
                        {options}
                    </select>
                    <button className="chapterInsertButton" onClick={this.onInsert}>{buttonText}</button>
                </div>
            </div>
        );
    }
}

export default ChapterEditView;