/**
 * Created by davidcaddy on 27/01/2017.
 */

import React, { Component } from 'react';
import './nodeTwo.css';
import deleteIcon from '../../resources/deleteIcon.svg';
import leftArrowIcon from '../../resources/nodeMoveArrowLeft.svg';
import rightArrowIcon from '../../resources/nodeMoveArrowRight.svg';

class NodeTwo extends Component {

    constructor() {
        super();

        this.onDelete = this.onDelete.bind(this);
        this.generateArrow = this.generateArrow.bind(this);
        this.onMoveLeft = this.onMoveLeft.bind(this);
        this.onMoveRight = this.onMoveRight.bind(this);
    }

    onDelete(event) {
        event.stopPropagation();

        this.props.deleteAction();
    }

    generateArrow(color, connectionIndex, key, isParent, connectingNode) {
        let svgArrowIcon = <svg alt={connectionIndex} className="nodeTwoArrowIcon" key={key} width="68px" height="96px" viewBox="0 0 68 96" pointerEvents="none" version="1.1">
            <path fillRule="nonzero" fill={color} d="M28.5,30.5 L28.5,7 L28.5,1 L40.5,1 L40.5,7 L40.5,30.5 L66.5,30.5 L34.5,94.5 L2.5,30.5 L28.5,30.5 Z"/>
        </svg>;

        var title = "Path " + connectionIndex;
        if (connectingNode != null) {
            title = "Chapter: " + (connectingNode.chapterIndex + 1) + "\nNode: " + connectingNode.title;
        }

        let arrow = <div title={title} className="nodeTwoArrow" key={key}>
            <div className={isParent ? "nodeTwoHidden" : ""}>
                {"P" + connectionIndex}
            </div>
            {svgArrowIcon}
            <div className={isParent ? "" : "nodeTwoHidden"}>
                {"P" + connectionIndex}
            </div>
        </div>;

        return arrow;
    }

    onMoveLeft(event) {
        event.stopPropagation();

        if (!this.props.isFirst) {
            this.props.moveLeftAction();
        }
    }

    onMoveRight(event) {
        event.stopPropagation();

        if (!this.props.isLast) {
            this.props.moveRightAction();
        }
    }

    render() {
        let parentArrows = [];
        for (let i in this.props.connectionColors.parentColors) {
            if (this.props.connectionColors.parentColors.hasOwnProperty(i)) {
                parentArrows.push(this.generateArrow(this.props.connectionColors.parentColors[i].color,
                    this.props.connectionColors.parentColors[i].index,
                    "Path " + i,
                    false,
                    this.props.connectionColors.parentColors[i].toNode))
            }
        }

        let childArrows = [];
        for (let i in this.props.connectionColors.childColors) {
            if (this.props.connectionColors.childColors.hasOwnProperty(i)) {
                childArrows.push(this.generateArrow(this.props.connectionColors.childColors[i].color,
                    this.props.connectionColors.childColors[i].index,
                    "Path " + i,
                    true,
                    this.props.connectionColors.childColors[i].fromNode))
            }
        }

        return (
            <div className="nodeTwoOuterContainer">
                <div className="nodeTwoArrowContainer">
                    {parentArrows}
                </div>
                <div className="nodeTwo">
                    <div className="nodeTwoTitleBlock">
                        <img src={deleteIcon} className="nodeTwoDeleteIcon" alt="delete" onClick={this.onDelete}/>
                        <div className="nodeTwoMoveArrowsContainer">
                            <img title="Move Left" src={leftArrowIcon} className={this.props.isFirst ? "nodeTwoMoveLeftArrowIconDisabled" : "nodeTwoMoveLeftArrowIcon"} alt="Move Left" onClick={this.onMoveLeft}/>
                            <img title="Move Right" src={rightArrowIcon} className={this.props.isLast ? "nodeTwoMoveRightArrowIconDisabled" : "nodeTwoMoveRightArrowIcon"} alt="Move Right" onClick={this.onMoveRight}/>
                        </div>
                        <div className="nodeTwoTitle" onClick={this.props.editAction}>
                            {this.props.title}
                        </div>
                    </div>
                    <div className="nodeTwoDescription">
                        {this.props.description}
                    </div>
                    <div className="extraLineObscurer"/>
                </div>
                <div className="nodeTwoArrowContainer">
                    {childArrows}
                </div>
            </div>
        );
    }
}

export default NodeTwo;