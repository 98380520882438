/**
 * Created by david on 24/01/2017.
 */

import React, { Component } from 'react';
import './team.css'
import alexAvatar from '../resources/alexAvatarRound.svg';
import axelAvatar from '../resources/axelAvartarRound.svg';
import davidAvatar from '../resources/davidAvartarRound.svg';

class Team extends Component {
    render() {
        return (
                <div className="section">
                    <div className="group">
                        <div>
                            <img src={alexAvatar} className="avatar" alt="logo"/>
                            <p><b>Alex Carr</b></p>
                            <p><i>Narrative Director</i></p>
                        </div>
                    </div>
                    <div className="group">
                        <div>
                            <img src={axelAvatar} className="avatar" alt="logo"/>
                            <p><b>Axel Freeman</b></p>
                            <p><i>Lead Designer</i></p>
                        </div>
                    </div>
                    <div className="group">
                        <div>
                            <a href="https://twitter.com/davocaddy">
                                <img src={davidAvatar} className="avatar" alt="logo"/>
                            </a>
                            <p><b>David Caddy</b></p>
                            <p><i>Code Monkey</i></p>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Team;
