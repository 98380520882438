/**
 * Created by davidcaddy on 29/01/2017.
 */

import React, { Component } from 'react';
import './nodeEditView.css';
import Select from 'react-select';
import './reactSelectAltStyling.css';
import deleteIconDark from '../../resources/deleteIconDark.svg';

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class NodeEditView extends Component {

    constructor() {
        super();

        this.state = {
            chapter: 0,
            selectedConnections: null,
            title: "",
            description: ""
        };

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.onChapterSelect = this.onChapterSelect.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onConnectionSelect = this.onConnectionSelect.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        let title = "";
        let description = "";
        let chapter = nextProps.numberOfChapters;
        let id = null;
        if (nextProps.selectedNode != null) {
            title = nextProps.selectedNode.title;
            description = nextProps.selectedNode.description;
            chapter = nextProps.selectedNode.chapter;
            id = nextProps.selectedNode.id;
        }

        this.setState({
            chapter: chapter,
            title: title,
            description: description,
            id: id,
            selectedConnections: nextProps.selectedConnections
        });
    }

    onBackgroundViewClick(event) {
        this.props.onDone(null);
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    onChapterSelect(event) {
        this.setState({chapter: event.target.value});
    }

    onSave(event) {
        let nodeInfo = {};
        if ((this.state.title != null) && (this.state.title.length > 0)) {
            nodeInfo.title = this.refs.titleInput.value;
            nodeInfo.chapterIndex = parseInt(this.refs.chapterSelect.value, 10);
            // just "chapter" for Legacy reasons
            nodeInfo.chapter = nodeInfo.chapterIndex;
            nodeInfo.description = this.refs.descriptionInput.value;
            if (this.props.selectedNode != null) {
                nodeInfo.index = this.props.selectedNode.index;
            }

            if (this.state.selectedConnections != null) {
                let childNodes = [];
                for (let connection of this.state.selectedConnections) {
                    childNodes.push(connection.value);
                }
                nodeInfo.children = childNodes;
            }

            if (this.props.selectedNode != null) {
                nodeInfo.id = this.props.selectedNode.id;
                if (this.props.selectedNode.chapter !== nodeInfo.chapter) {
                    nodeInfo.previousChapter = this.props.selectedNode.chapter;
                }
            }
        }

        this.props.onDone(nodeInfo);
    }

    onConnectionSelect(selectedConnections) {
        this.setState({
            selectedConnections: selectedConnections
        });
    }

    onTitleChange(event) {
        this.setState({
            title: event.target.value
        });
    }

    onDescriptionChange(event) {
        this.setState({
            description: event.target.value
        });
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        let options = [];
        for (let i = (this.props.numberOfChapters - 1); i >= 0; i--) {
            options.push(<option key={"c" + i} value={i}>{"Chapter " + (i + 1)}</option>);
        }

        let buttonText = "Add new node";
        let indexSelectLabelText = "Add to:";
        if (this.props.selectedNode != null) {
            buttonText = "Save node";
            indexSelectLabelText = "Chapter:";
        }


        let childNodeOptions = this.props.availableNodes.filter((option) => option.chapter >= this.state.chapter);

        return(
            <div className="nodeEditBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <div className="nodeEditViewFrame" onClick={this.onFrameClick}>
                    <img src={deleteIconDark} className="chapterEditCancelIcon" alt="cancel" onClick={this.onBackgroundViewClick} />
                    <div className="connectionsSelectContainer">
                        <div className="connectionsTitleLabel">Child nodes:</div>
                        <Select
                            name="form-field-name"
                            value={this.state.selectedConnections}
                            options={childNodeOptions}
                            onChange={this.onConnectionSelect}
                            searchable={false}
                            multi={true}
                        />
                    </div>
                    <div className="topContainer">
                        <div className="nodeTitleContainer">
                            <div className="nodeTitleLabel">
                                Title:
                            </div>
                            <textarea className="titleInput" ref="titleInput" value={this.state.title} onChange={this.onTitleChange} />
                        </div>
                        <div className="chapterSelectLabel">{indexSelectLabelText}</div>
                        <select value={this.state.chapter} ref="chapterSelect" className="chapterSelect" onChange={this.onChapterSelect}>
                            {options}
                        </select>
                    </div>
                    <div className="descriptionTitleLabel">Description:</div>
                    <textarea className="descriptionTextArea" ref="descriptionInput" value={this.state.description} onChange={this.onDescriptionChange}/>
                    <button className="nodeInsertButton" onClick={this.onSave}>{buttonText}</button>
                </div>
            </div>
        );
    }
}

export default NodeEditView;