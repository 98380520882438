/**
 * Created by davidcaddy on 10/01/2017.
 */

import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <p>Awesome stuff coming soon!</p>
                <p>Follow us on <a href="https://twitter.com/axadalstudios">Twitter</a> for updates!</p>
            </div>
        );
    }
}

export default Home;
